<template>
  <div class="h-screen w-screen bg-neutral-9 contrast:bg-c-secondary-0">
    <div id="layout" class="flex place-content-center justify-items-center">
      <div
        class="mx-2 my-10 max-w-xl space-y-4 border p-4 rounded border-neutral-7 shadow-md bg-base-2"
      >
        <NuxtPage />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
useNoscript();
useFavicon();
</script>
